@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

:root {
  --tr: all 0.5s ease 0s;
  --ch1: #05478a;
  --ch2: #0070e0;
  --cs1: #005e38;
  --cs2: #03a65a;
  --cw1: #c24914;
  --cw2: #fc8621;
  --ce1: #851d41;
  --ce2: #db3056;
}

/* 
@keyframes show-toast { 
	0%, 50%, 100% { max-height: 0; opacity: 0; }
	10%, 25% { max-height: 15rem; opacity: 1; }
} */

.toast {
  background: #fff;
  color: #f5f5f5;
  padding: 1rem 2rem 1rem 1rem;
  text-align: center;
  border-radius: 1rem;
  position: relative;
  font-weight: 300;
  margin: 0.3rem 0.3rem;
  text-align: left;
  max-width: 16rem;
  transition: var(--tr);
  opacity: 1;
  border: 0.15rem solid #fff2;
  box-shadow: 0 0 0.4rem 0 #1a1f4360;
  border-left: 7px solid #bd1d23;
}

/* .toast:before {
  content: "";
  position: absolute;
  width: 0.5rem;
  height: calc(100% - 1.5rem);
  top: 0.75rem;
  left: 0.5rem;
  z-index: 0;
  border-radius: 1rem;
  background: var(--clr);
} */

.toast h3 {
  font-size: 1rem;
  margin: 0;
  line-height: 1rem;
  font-weight: 600;
  position: relative;
  color: var(--clr);
  width: 230px; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Poppins/bold';
}

.toast p {
  position: relative;
  font-size: 0.95rem;
  z-index: 1;
  margin: 0.25rem 0 0;
  color: #595959;
  line-height: 1.3rem;
  font-family: 'Poppins/bold';
}

.toast-item.error {
  animation-delay: 0s;
  cursor: pointer;
}

.toast.error {
  --bg: var(--ce1);
  --clr: var(--ce2);
  --brd: var(--ce3);
}

.icon-error:after {
  content: "+";
  font-size: 2.85rem;
  line-height: 1.2rem;
  transform: rotate(45deg);
}

.toast a {
  color: var(--clr);
}

.toast a:hover {
  color: var(--bg);
}
@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
