
.tabset > input[type="radio"] {
    position: absolute;
    left: -200vw;
    display: 'none';
  }
  
  .tabset .tab-panel {
    display: none;
  }
  
  .tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
  .tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
  .tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
  .tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
  .tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
  .tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
    display: block;
  }
  
  /*
   Styling
  */
  /* body {
    font: 16px/1.5em "Overpass", "Open Sans", Helvetica, sans-serif;
    color: #333;
    font-weight: 300;
  } */
  
  .tabset > label {
    position: relative;
    display: inline-block;
    padding: 12px 24px;
    border: 1px solid transparent;
    justify-content: 'center';
    /* font-family: ''; */
    border-bottom: 0;
    cursor: pointer;
    font-weight: 600;
    font-size: '16px';
    border-top-right-radius: 8px; /* Add border-radius to top-left corner */
    border-top-left-radius: 8px;
    color: #101828;
 
  }
/*   
  .tabset > label::after {
    content: "";
    position: absolute;
    left: 15px;
    bottom: 10px;
    width: 22px;
    height: 4px;
    background: #8d8d8d;
  } */
  .tabset{
    text-align: left;
    /* margin-left: -50rem; */
  }
/*   
  input:focus-visible + label {
    outline: 2px solid rgba(0,102,204,1);
    border-radius: 3px;
  } */
  
  /* .tabset > label:hover,
  .tabset > input:focus + label,
  .tabset > input:checked + label {
    color: #06c;
  } */
/*   
  .tabset > label:hover::after,
  .tabset > input:focus + label::after,
  .tabset > input:checked + label::after {
    background: #06c;
  } */
  
  .tabset > input:checked + label {
    border-color: #ccc;
    border-bottom: 1px solid #fff;
    margin-bottom: -1px;
    color: #C4232B;
  }
  
  .tab-panel {
    padding: 30px 0;
    border-top: 1px solid #ccc;
  }
  
  /*
   Demo purposes only
  */
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
/*   
  body {
    padding: 30px;
  } */
  
  /* .tabset {
    max-width: 65em;
  } */