/* @font-face {
  font-family: "Poppins";
  src: url("./Assets/Fonts/Poppins/Poppins-Black.ttf") format("black"),
    url("./Assets/Fonts/Poppins/Poppins-Bold.ttf") format("bold"),
    url("./Assets/Fonts/Poppins/Poppins-ExtraBold.ttf") format("extrabold");
  font-style: normal;
} */

body {
  margin: 0;
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  width: 9px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f6f8f9;
  border-radius: 5px;
  height: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #e6e6e6;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}
.mapboxgl-ctrl-logo {
  display: none !important;
}
