@keyframes gradientMove {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .animated-heading {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    background: linear-gradient(90deg, #BD1D23, #FF5733);
    background-size: 200% 200%; /* Required for animation to work */
    -webkit-background-clip: text;
    color: transparent;
    animation: gradientMove 3s ease-in-out infinite;
  }
  