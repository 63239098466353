:root {
  --tr: all 0.5s ease 0s;
  --ch1: #05478a;
  --ch2: #0070e0;
  --cs1: #005e38;
  --cs2: #03a65a;
  --cw1: #c24914;
  --cw2: #fc8621;
  --ce1: #851d41;
  --ce2: #db3056;
}

/* 
@keyframes show-toast { 
	0%, 50%, 100% { max-height: 0; opacity: 0; }
	10%, 25% { max-height: 15rem; opacity: 1; }
} */

.toast {
  background: #fff;
  color: #f5f5f5;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 9px;
  padding-bottom: 9px;
  text-align: center;
  border-radius: 8px;
  position: relative;
  font-weight: 300;
  margin-left: 0px;
  margin-right: 2px;
  margin-top: 2px;
  margin-bottom: 10px;
  text-align: left;
  transition: var(--tr);
  opacity: 1;
  box-shadow: 0 0 4px 0 #1a1f4360;
  border-left: 4px solid #bd1d23;
}

/* .toast:before {
  content: "";
  position: absolute;
  width: 0.5rem;
  height: calc(100% - 1.5rem);
  top: 0.75rem;
  left: 0.5rem;
  z-index: 0;
  border-radius: 1rem;
  background: var(--clr);
} */

.toast h3 {
  color: var(--clr);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
}

.toast p {
  position: relative;
  font-size: 0.95rem;
  z-index: 1;
  margin: 0.25rem 0 0;
  color: #595959;
  line-height: 1.3rem;
  /* font-family: 'Poppins/bold'; */
}

.toast-item.error {
  animation-delay: 0s;
  cursor: pointer;
}

.toast.error {
  --bg: var(--ce1);
  --clr: var(--ce2);
  --brd: var(--ce3);
}

.icon-error:after {
  content: "+";
  font-size: 2.85rem;
  line-height: 1.2rem;
  transform: rotate(45deg);
}

.toast a {
  color: var(--clr);
}

.toast a:hover {
  color: var(--bg);
}

.map-container {
  height: 400px;
}

.sidebar {
  background-color: rgb(35 55 75 / 90%);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

/* ----------- cities marker css ----------- */

.custom-marker {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #23374b;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  /* position: relative; */
}

.custom-marker .number {
  position: absolute;
  top: -5px;
  left: 100%;
  transform: translateX(-50%);
  background-color: #cc1212;
  color: #ffffff;
  border-radius: 50%;
  padding: 2px;
  font-size: 10px;
  z-index: 999;
  min-width: 18px;
}

.custom-marker .plus-sign {
  position: absolute;
  top: -5px;
  /* Adjust as needed */
  left: 72%;
  transform: translateX(-50%);
  font-size: 10px;
  color: #ffffff;
  /* Adjust color as needed */
}

.custom-marker .image-cutout {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 36px;
  /* Adjusted size for image cutout */
  height: 36px;
  /* Adjusted size for image cutout */
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.custom-marker .image-cutout img {
  width: 100%;
  height: auto;
  display: block;
}

.custom-marker .pin-tip {
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 8px solid #23374b;
}

.custom-marker .city-name {
  position: absolute;
  bottom: -36px;
  /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  background-color: rgb(35 55 75 / 90%);
  padding: 4px 8px;
  border-radius: 8px;
  z-index: 999;
}

/* region markers */

.region-marker {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 4px solid #a91919;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  /* position: relative; */
  background-color: #fff;
  cursor: pointer;
}

.region-marker-green {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 4px solid #19a927;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  /* position: relative; */
  background-color: #fff;
}

.region-marker .pin-tip {
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 8px solid #a91919;
}

.region-marker-green .pin-tip {
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 8px solid #19a927;
}

.region-marker .number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #000;
  /* Change color as needed */
}

.region-marker-green .number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  color: #000;
  /* Change color as needed */
}

.tooltip-container {
  margin: 12px;
  border-radius: 4px;
}

.title_container_tooltip {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  gap: 8px;
  margin-top: 12px;
  margin-bottom: 8px;
}

.title_text_container_tooltip {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.tooltip-heading {
  font-family: 'Poppins';
  color: #BD1D23;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  margin: 0px
}

.tooltip-item {
  color: #192126;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0px;
  margin-top: 8px;
}

/* popup css */
/* 
.popup-container {
  font-family: Arial, sans-serif;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  max-width: 300px;
} */

.popup-heading {
  margin-top: 0;
}

.popup-item {
  margin-bottom: 5px;
  text-align: left;
}

// .container {
//   /* max-width: 400px; */
//   width: 16%;
//   /* margin: 40px auto; */
// }
// .container_open {
//   /* max-width: 400px; */
//   width: 21%;
//   /* margin: 40px auto; */
// }

.flex {
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  background-color: #f8e8e9;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid #f8e8e9;
  box-shadow: 0px 2px 3.667px 0px rgba(0, 0, 0, 0.09);
  /* font-family: 'Poppins/bold' */
  /* box-shadow: inset 0 0 5px #999; */
}

a {
  cursor: pointer;
  padding: 5px;
  background-color: inherit;
  flex-grow: 1;
  text-align: center;
  border-radius: 7px;
  font-size: 11px;
  font-weight: 500;
}

.toggleStyle {
  font-size: 14px;
  font-weight: 500;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.selected {
  border: 1px solid #bd1d23;
  background-color: #bd1d23;
  color: white;
  box-shadow: inset 0 0 10px #bd1d23;
}

.container-table {
  width: 8%;
}

.flex-table {
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-between;
  background-color: #f8e8e9;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid #f8e8e9;
  box-shadow: 0px 2px 3.667px 0px rgba(0, 0, 0, 0.09);
  /* box-shadow: inset 0 0 5px #999; */
}

.table-icon {
  display: flex;
}

.table-icon:hover {
  border: 1px solid #bd1d23;
  background-color: #bd1d23;
  color: white;
  box-shadow: inset 0 0 10px #bd1d23;
}

.table-icons {
  border: 1px solid #bd1d23;
  display: flex;
  background-color: #bd1d23;
  color: white;
  box-shadow: inset 0 0 10px #bd1d23;
}

/* @keyframes bounce {
  0% {
      transform: translateY(0);
  }
  100% {
      transform: translateY(-10px);
  }
} */

#header {
  position: absolute;
  width: 220px;
}

#header .pro-sidebar {
  height: 100vh;
}

#header .closemenu {
  color: #000;
  position: absolute;
  right: 0;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 55px;
  cursor: pointer;
}

#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}

#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}

#header .pro-sidebar-inner {
  background-color: white;
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}

#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: #000;
  font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  margin: 10px 0px;
  font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: #fbf4cd;
  color: #000;
  border-radius: 3px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
  color: #000;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background-image: linear-gradient(0deg, #fece00 0%, #ffe172 100%);
}

#header .logo {
  padding: 20px;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}




h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 40px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

h1 span {
  display: block;
  font-size: 0.5em;
  line-height: 1.3;
}

h1 em {
  font-style: normal;
  font-weight: 600;
}

/* === HEADING STYLE #1 === */
.one h1 {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.one h1:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: #b80000;
  transform: rotateX('0deg');
}

.one h1:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 25px;
  left: 50%;
  margin-left: -50px;
  background-color: #b80000;
}

.crossButton {
  background: #fff;
  border: none;
  cursor: pointer;
}

.ps__rail-x {
  display: none !important;
}

.ps__rail-y {
  display: none !important;
}

.box {
  background: #ffffff;
  box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 12px 18px;
}

.active_alarms_main {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  gap: 12px
}

.active_alarms_card_layout {
  width: 73px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 0px;
  cursor: pointer;

  h3 {
    color: #000000;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 7.4px;
    margin-top: 6.58px;
    height: 16.44px;
  }

  span {
    color: #000000;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    height: 16.44px;
  }
}

.alerts_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 328px;
  height: 18px;
  margin-top: 20px;
  margin-bottom: 19px;

  .alerts_title_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    img {
      height: 32px;
      width: 32px;
    }
  }

  h3 {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
}

.alerts_main {
  margin-left: 33px;
  margin-right: 21px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
}

.alerts_list_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 328px;
  height: calc(100vh - 265px)
}


.single_alert_card {
  position: relative;
  /* Ensure relative positioning for absolute pseudo-element */
  overflow: hidden;
  /* Hide overflow to contain the gradient animation */
  border-bottom: 1px solid transparent;
  /* Initially transparent border */
  cursor: pointer;
  transition: border-color 0.2s;
  width: 300px;
  flex-shrink: 0;
  // border-radius: 8px;
  // border-left: 2px solid #BD1D23;
  background: #FFF;
  // border-bottom: 2px solid #E8E8E8;
  // border-bottom: 2px solid;
  padding-top: 9px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 14px;
  margin-bottom: 6px;
  margin-top: 2px;
  margin-right: 2px;
  // box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transition: border-left-width 0.2s;

  .top_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;

    p {
      margin: 0px;
    }

    .date_time {
      color: #828282;
      text-align: right;
      font-size: 9px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    .top_row_left {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px
    }

    .city_box {
      display: flex;
      padding: 0px 8px;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      border-radius: 50px;
      border: 1px solid #C0C0C0;
      background: #FFF;
      color: #6E6E6E;
      text-align: right;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    .region_box {
      display: flex;
      padding: 0px 8px;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      border-radius: 50px;
      background: #E8F1FD;
      color: #415F8C;
      text-align: right;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }

  // p{
  //   font-size: 12px;
  //   margin: 0px;
  // }
  h3 {
    color: #BD1D23;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ;

  .alerts_info {
    display: flex;
    flex-direction: row;
    gap: 7px;
    color: #757F82;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 4px;

    p {
      margin: 0px;
    }
  }
}

// .single_alert_card {
//   width: 300px;
//   flex-shrink: 0;
//   background: #FFF;
//   padding: 9px 12px 14px;
//   margin: 2px 2px 6px;
//   position: relative; /* Ensure relative positioning for absolute pseudo-element */
//   overflow: hidden; /* Hide overflow to contain the gradient animation */
//   border-bottom: 2px solid transparent; /* Initially transparent border */
//   cursor: pointer;
//   transition: border-color 0.2s; /* Smooth transition for border color */
// }

// .single_alert_card::after {
//   content: '';
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   height: 2px; /* Border thickness */
//   background: linear-gradient(to right, #BD1D2380, #FFD60080); /* Gradient colors */
//   animation: moveGradient 4s linear infinite; /* Animation settings */
// }
.single_alert_card::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  /* Border thickness */
  background: linear-gradient(to right, #FFD60080, #BD1D2380);
  /* Gradient colors */
  background-size: 200% 100%;
  /* Double the width of the gradient */
  animation: moveGradient 4s linear infinite;
  /* Animation settings */
}

@keyframes moveGradient {
  0% {
    background-position: 0 0;
    /* Start gradient from the left */
  }

  100% {
    background-position: -200% 0;
    /* Move gradient to the left by its full width */
  }
}

.single_alert_card:hover {
  border-color: #E8E8E8;
  /* Change border color on hover */
}

// .single_alert_card:hover {
//   border-left-width: 7px;
// }

.alert_notification_grid {
  box-shadow: 5px 0px 10px 0px rgba(0, 0, 0, 0.2);
  height: calc(100vh - 72px);
}

.open_alert_drawer {
  width: 382px
}

.close_alert_drawer {
  width: 0%
}

.open_alert_content {
  width: calc(100% - 382px)
}

.close_alert_content {
  width: 100%
}

.date-field-wrapper {
  display: flex;
  align-items: center;
  width: 113px;
  padding: 10px 14px;
  border-radius: 8px;
  background: #F8E8E9;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);

}

.calendar-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 11px;
  cursor: pointer;
}

.date-field {
  border: none;
  background: none;
  width: 78px;
  height: 14px;
  color: #444;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  padding: 0px;
}

.flatpickr-calendar {
  border-radius: 8px;
}

.flatpickr-weekdays {
  background-color: #F8E8E9;
}

.flatpickr-day {
  color: #444;
}

.flatpickr-day.today {
  background-color: #BD1D23;
  border: 1px solid #BD1D23;
  color: white;
  font-weight: 600;
}

.flatpickr-month {
  background-color: #F8E8E9;
}

.flatpickr-prev-month,
.flatpickr-next-month {
  color: #444;
}

.flatpickr-day.today:before {
  border: 1px solid #BD1D23;
  color: white;
}

.flatpickr-day.selected:before {
  background-color: #BD1D23;
  color: white;
}

.flatpickr-day:not(.prevMonthDay):not(.nextMonthDay):hover {
  background-color: rgba(189, 29, 35, 0.5);
}

// @media(min-width:1023px){
//   .alert_notification_grid{
//     height: calc(100vh - 1px);
//   }
//   // .alerts_list_main{
//   //   height: calc(100vh - 320px)
//   // }
// }

@media (max-height: 500px) and (min-height: 400px) {
  .alert_notification_grid{
        height: calc(100vh - 1px);
      }
}

@media (max-height: 600px) and (min-height: 501px) {
  .alert_notification_grid{
    height: calc(100vh - 50px);
  }
  .mapboxgl-canvas{
    height: 492px;
  }
}

@media (max-height: 650px) and (min-height: 601px) {
  .alert_notification_grid{
    height: calc(100vh - 50px);
  }
  .mapboxgl-canvas{
    height: 493px;
  }
}

@media (max-height: 700px) and (min-height: 651px) {
  .alert_notification_grid{
    height: calc(100vh - 50px);
  }
  .mapboxgl-canvas{
    height: 493px;
  }
}

@media (max-height: 750px) and (min-height: 701px) {
  .alert_notification_grid{
    height: calc(100vh - 72px);
  }
}

.scrollbar-container {
  overflow: auto !important; /* Ensure it scrolls */
  height: 100% !important;    /* Ensure it takes full height */
}

/* Ensure scrolling works on the .ps class */
.ps {
  overflow: auto !important; /* Force auto scroll behavior */
}

/* Additional overrides for active scroll states */
.ps--active-y {
  overflow-y: auto !important; /* Allow vertical scrolling */
}
.alerts_list_main .ps {
  overflow: auto !important; /* Allow scrolling */
  height: 100% !important;    /* Ensure it takes full height */
  width: 98%;
}

.scrollbar-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.scrollbar-container::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #ff7f50, #bd1d23); /* Gradient colors */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

.scrollbar-container::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #bd1d23, #ff4500); /* Darker gradient on hover */
}

.scrollbar-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Scrollbar track color */
  border-radius: 10px;
}

/* For Firefox (Using scrollbar-color and scrollbar-width) */
.scrollbar-container {
  scrollbar-width: thin; /* Makes the scrollbar thinner */
  scrollbar-color: #ff7f50 #f1f1f1; /* Thumb color and track color */
}

/* For Internet Explorer and Edge */
.scrollbar-container {
  -ms-overflow-style: -ms-autohiding-scrollbar; /* Auto-hiding scrollbar for IE */
}