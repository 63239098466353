@media(max-width:1920px){
  // .alert_notification_grid{
  //   height: calc(100vh - 98px);
  // }
  // .alerts_list_main{
  //   height: calc(100vh - 320px)
  // }
}

@media(max-width:1680px){
  // .alert_notification_grid{
  //   height: calc(100vh - 72.5px);
  // }
  // .alerts_list_main{
  //   height: calc(100vh - 295px)
  // }
}

@media(max-width:1440px){
  // .alert_notification_grid{
  //   height: calc(100vh - 65px);
  // }
  // .alerts_list_main{
  //   height: calc(100vh - 290px)
  // }
}

@media (max-width:1365px){   // MAC
  .toast h3 {
    width: 300px !important; 
  }
  // .alert_notification_grid{
  //   height: calc(100vh - 78.5px);
  // }
}

@media(max-width:1279px){

}

@media(max-width:1024px){

}

@media(max-width:1023px){

}

@media(max-width:642px){

}

@media(max-width:440px){

}

@media(max-width:380px){

}

.scrollbar-container {
  overflow: scroll !important; /* Ensure it scrolls */
  height: 100% !important;    /* Ensure it takes full height */
}

/* Ensure scrolling works on the .ps class */
.ps {
  overflow: scroll !important; /* Force auto scroll behavior */
}

/* Additional overrides for active scroll states */
.ps--active-y {
  overflow-y: scroll !important; /* Allow vertical scrolling */
}
.alerts_list_main .ps {
  overflow: auto !important; /* Allow scrolling */
  height: 100% !important;    /* Ensure it takes full height */
}